import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Autoplay, Navigation } from "swiper";
SwiperCore.use([Navigation, Autoplay]);

function ServiceArea(props) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const serviceSlider = {
    loop: true,
    speed: 2500,
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    slidesPerView: 3,
    spaceBetween: 30,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
      },
    },
  };
  return (
    <>
      <section
        className={`${"services-area sec-mar"} ${props.layoutClass}`}
        id="servicios"
      >
        <div className="container">
          <div
            className="title-wrap wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className={`${"sec-title"} ${props.white}`}>
              <span>Soluciones</span>
              <h2>Servicios</h2>
              <p>
                Bellum se destaca por su creatividad y simplicidad en la
                creación de soluciones personalizadas para cada cliente,
                alineadas con las necesidades del negocio y a la vanguardia de
                la tecnología.
              </p>
            </div>
          </div>
          <Swiper {...serviceSlider} className="swiper services-slider">
            <div className="swiper-wrapper">
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="200ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-1.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Diseño Web</h4>
                  <p>
                    En Bellum, creemos que el diseño web es esencial para el
                    éxito de cualquier negocio. Ofrecemos una amplia gama de
                    servicios de diseño web para personas de todos los niveles
                    de experiencia. Podemos ayudarlo a crear un sitio web que
                    cumpla con sus objetivos, ya sea simple o complejo.
                    <br />
                    <br />
                  </p>
                  <br />
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="400ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-2.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Aplicaciones móviles</h4>
                  <p>
                    En Bellum ofrecemos una amplia gama de servicios de
                    desarrollo de aplicaciones móviles para personas de todos
                    los niveles de experiencia. Podemos ayudarlo a crear una
                    aplicación móvil que cumpla con sus objetivos, ya sea simple
                    o compleja.
                  </p>
                </div>
              </SwiperSlide>
              <SwiperSlide
                className="swiper-slide wow animate fadeInUp"
                data-wow-delay="600ms"
                data-wow-duration="1500ms"
              >
                <div className={`${"single-service"} ${props.layoutClass}`}>
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/service-icon-3.png"
                      }
                      alt="service-icon"
                    />
                  </div>
                  <h4>Desarrollo De Software</h4>
                  <p>
                    Podemos ayudarle a crear una solución perfecta para sus
                    necesidades. Contamos con expertos en una variedad de
                    lenguajes y plataformas, y ofrecemos una variedad de
                    paquetes de servicios para que pueda encontrar el que se
                    adapte a su presupuesto.
                  </p>
                  <br />
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default ServiceArea;
