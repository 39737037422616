import React from "react";
import { Link } from "react-router-dom";
import "swiper/css/autoplay";
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Autoplay,
  Navigation,
  EffectFade,
  Pagination,
} from "swiper";
SwiperCore.use([Navigation, Autoplay, EffectFade, Pagination]);
function Testimonial() {
  const testimonialSlider = {
    loop: true,
    slidesPerView: 2,
    centeredSlides: true,
    duration: 5000,
    speed: 1000,
    autoplay: {
      delay: 3500,
      isableOnInteraction: true,
    },
    spaceBetween: 30,
    slideToClickedSlide: true,

    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      576: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1400: {
        slidesPerView: 2,
      },
      1800: {
        slidesPerView: 2,
      },
    },
  };
  return (
    <>
      <section className="testimonial-area" id="#testimonios">
        <div className="container-fluid p-0">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Testimonios</span>
              <h2>Opiniones de nuestro clientes</h2>
              <p>
                Aquí hay algunas cosas que nuestros clientes han dicho sobre
                nuestros servicios.
              </p>
            </div>
          </div>
          <Swiper {...testimonialSlider} className="swiper testimonial-slider">
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Juan Pablo</h5>
                  <span>Director de Marketing Digital</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    Estoy muy satisfecho con el servicio que recibí de Bellum
                    Software para crear la página web de mi empresa. Desde el
                    primer momento, el equipo de Bellum fue muy profesional y
                    atento. Me escucharon detenidamente para comprender mis
                    necesidades y objetivos, y me ofrecieron soluciones
                    personalizadas.
                  </p>
                  <div className="reviewer"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Anónimo</h5>
                  <span>Creador de contenido</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    El proceso de creación de la página web fue muy fluido y
                    transparente. En todo momento, tuve acceso a los avances del
                    proyecto y pude realizar comentarios y sugerencias. El
                    resultado final es una página web que cumple con todas mis
                    expectativas.
                  </p>
                  <div className="reviewer"></div>
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="single-testimonial">
                  <div className="quote">
                    <i className="fas fa-quote-right" />
                  </div>
                  <h5>Marta Lacoste</h5>
                  <span>Dentista</span>
                  <div className="stars">
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                    <Link to={"#"}>
                      <i className="fas fa-star" />
                    </Link>
                  </div>
                  <p>
                    Hace unos meses, decidí crear una página web para mi
                    empresa. Estaba buscando una empresa que ofreciera un
                    servicio de diseño web profesional y de calidad, y encontré
                    Bellum. Desde el primer momento, la atención al cliente fue
                    excelente. Me asesoraron en todo momento sobre las
                    necesidades de mi empresa y me ayudaron a crear un diseño
                    web a medida.
                  </p>
                  <div className="reviewer"></div>
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
          <div className="swiper-button-next" />
          <div className="swiper-button-prev" />
        </div>
      </section>
    </>
  );
}

export default Testimonial;
