const data = [
  {
    id: 1,
    title: "App Web",
    image: "img/project/project-1.jpg",
    category: "developing",
    details: "Select Structural",
  },
  {
    id: 2,
    title: "Sitio Web",
    image: "img/project/project-2.jpeg",
    category: "web",
    details: "PrimerFan",
  },
  {
    id: 3,
    title: "Landing Page",
    image: "img/project/project-3.jpg",
    category: "App",
    details: "The Channel CO",
  },
];

export default data;
