import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutArea(props) {
  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <section className="about-area sec-mar-bottom" id="nosotros">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-6 or-2 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className={`${"sec-title layout2"} ${props.white}`}>
                <span>Nosotros</span>
                <h2>Somos BELLUM. </h2>
              </div>
              <div className={`${"about-left"} ${props.layoutClass}`}>
                <h3>
                  Convertimos tus ideas en realidad, y las mejores ideas
                  comienzan en Bellum.
                </h3>
                <p>
                  Bellum Software es una agencia activa que ofrece servicios y
                  soluciones en línea. Esta empresa ha estado en la web desde el
                  2022, cuando surgió como una empresa innovadora. Desde
                  entonces, ha seguido creciendo y desarrollando nuevos
                  productos y servicios para satisfacer las necesidades de sus
                  clientes y adaptarse a los avances tecnológicos.
                </p>
                <div className={`${"company-since"} ${props.layoutClass}`}>
                  <div className="company-logo">
                    <img
                      src={`${process.env.PUBLIC_URL} ${props.image}`}
                      alt="about-images"
                    />
                  </div>

                  <h4>
                    Soluciones De Software a Medida
                    <span>Since 2022.</span>
                  </h4>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 or-1 wow animate fadeIn"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="about-right">
                <div className="banner-1">
                  <img
                    src={process.env.PUBLIC_URL + "/img/about-baner-1.jpg"}
                    alt="about-images"
                  />
                </div>
                <div className="banner-2">
                  <img
                    src={process.env.PUBLIC_URL + "/img/about-baner-2.jpg"}
                    alt="about-images"
                  />
                  <div className="banner2-inner"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          isOpen={isOpen}
          videoId="IUN664s7N-c"
          autoplay={1}
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutArea;
