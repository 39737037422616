import React from "react";
import { HashLink } from "react-router-hash-link";

function Hero2() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <section className="hero-area">
        <div id="particles-js" />
        <div className="verticale-social">
          <ul className="vertical-media">
            <li>
              <a
                href="https://www.facebook.com/people/Bellum-Software/61550344981663/"
                target="_blank"
              >
                Facebook
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/bellumweb/" target="_blank">
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/bellum-software/"
                target="_blank"
              >
                Linkedin
              </a>
            </li>
          </ul>
        </div>
        <div className="hero-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6">
                <div className="hero-content layout2">
                  <h1>
                    BELLUM" SOFTWARE<span>Servicios It.</span>
                  </h1>
                  <p>
                    Nos especializamos en la implementación de soluciones
                    tecnológicas en empresas de todos los tamaños. Nuestro
                    objetivo es ayudarte a mejorar la eficiencia de tus procesos
                    y aumentar tu productividad y tus ventas.
                  </p>
                  <div className="buttons">
                    <div className="cmn-btn">
                      <div className="line-1" />
                      <div className="line-2" />
                      <HashLink smooth to="/#nosotros">
                        Nosotros
                      </HashLink>
                    </div>
                    <div className="cmn-btn layout-two">
                      <div className="line-1" />
                      <div className="line-2" />
                      <HashLink
                        className="servicioButton"
                        smooth
                        to="/#servicios"
                      >
                        Servicios
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="hero-img">
                  <img
                    src={process.env.PUBLIC_URL + "/img/hero-vector.png"}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          href="https://api.whatsapp.com/send?phone=+5491173689691&text=Hola, vengo desde la web de Bellum y quisiera más información."
          class="float"
          target="_blank"
          rel="noreferrer"
        >
          <i class="fab fa-whatsapp"></i>
        </a>
        ;
      </section>
    </>
  );
}

export default Hero2;
