import React from "react";
import ProjectCardFilter from "./ProjectCardFilter";

function ProjectWrap2() {
  return (
    <>
      <div className="project-area sec-mar">
        <div className="container">
          <div className="title-wrap">
            <div className="sec-title white">
              <span>Trabajos</span>
              <h2>Ultimos Proyectos</h2>
              <p>
                Nuestros últimos proyectos: una mirada a nuestro trabajo más
                reciente y destacado
              </p>
            </div>
          </div>
          <ProjectCardFilter />
        </div>
      </div>
    </>
  );
}

export default ProjectWrap2;
