import React from "react";
import CountUp from "react-countup";
function FeaturesArea() {
  return (
    <>
      <section className="features-area">
        <div className="container">
          <div
            className="title-wrap  wow animate fadeInUp"
            data-wow-delay="200ms"
            data-wow-duration="1500ms"
          >
            <div className="sec-title white">
              <span>Casos estudio</span>
              <h2>Estadísticas</h2>
              <p>Algunos Datos sobre nuestra empresa y nuestros servicios.</p>
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="200ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-1.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={25} delay={3} duration={5} /> <sup>+</sup>
                  <h4>Projectos Completados</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="400ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-2.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={30} delay={3} duration={5} /> <sup>+</sup>
                  <h4>Clientes Satisfechos</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="600ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-3.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={6} delay={3} duration={5} /> <sup>+</sup>
                  <h4>Desarrolladores</h4>
                </div>
              </div>
            </div>
            <div
              className="col-md-6 col-lg-3 wow animate fadeInUp"
              data-wow-delay="800ms"
              data-wow-duration="1500ms"
            >
              <div className="single-feature">
                <div className="feature-inner">
                  <div className="icon">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/img/icons/feature-icon-1.png"
                      }
                      alt="Feature-img"
                    />
                  </div>
                  <CountUp end={30} delay={3} duration={5} /> <sup>+</sup>
                  <h4>Contratos Realizados</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default FeaturesArea;
