import React from "react";
import Footer from "../../common/Footer";
import Header from "../../common/Header";

import AboutArea from "../home/AboutArea";
import FeaturesArea from "../home/FeaturesArea";

import ServiceArea from "../home/ServiceArea";
import Testimonial from "../home/Testimonial";
import ProjectWrap2 from "../project/ProjectWrap2";
import Hero2 from "./Hero2";

function HomePageTwo() {
  return (
    <>
      <Header />
      <div className="creasoft-wrap layout2">
        <Hero2 />
        <ServiceArea layoutClass="layout2" white="white" />
        <AboutArea layoutClass="layout2" white="white" image="/img/logo.png" />
        <FeaturesArea />
        <ProjectWrap2 white="white" />
        <Testimonial />

        <Footer />
      </div>
    </>
  );
}

export default HomePageTwo;
